import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DocumentTable, EvaluationAssementor } from "../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { eduActions, getEvaluations, getCurrentEduContent, getCurrentUser, isAdmin, isOrganizer, isArchitect, getTotalCount } from '../../store';

const Columns = [
  { title: 'ID', dataIndex: 'eval_id', key: 'eval_id', width: '115px', className: 'exo2 f9 text-center' },
  { title: 'Title', dataIndex: 'title', key: 'title', width: '45%', search: true, render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9', supportServerSearch: true },
  { title: 'Learner', dataIndex: 'learner', key: 'learner', width: '27%', search: true, className: 'exo2 f9' },
  { title: 'Due', dataIndex: 'created_at', key: 'create_at', width: '13%', className: 'exo2 f9 text-center' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '12%', render: 'color', className: 'exo2 f9' },
]

export const EduEvaluationsScreen = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const _filter = useRef({ pageNo: 1, search: {} })
  const evaluations = useSelector(getEvaluations)
  const evaluation = useSelector(getCurrentEduContent);
  const user = useSelector(getCurrentUser);
  const totalCount = useSelector(getTotalCount.bind(null, 'assessments'))
  const [state, setState] = useState({ evaluation: null, userAnswers: null })
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    fetchAssessments()
    handleOpen();
  }, []);
  const handleOpen = () => {
    let open = searchParams.get('open');
    let userId = searchParams.get('userId');
    if (open && userId) {
      if (userId !== user.guid) {
        return navigate('/page-not-found')
      }
      handleEvaluationSelect({ id: open, ent_usr_id: userId, status: 'pending' });
      searchParams.delete('open');
      searchParams.delete('userId');
      setSearchParams(searchParams)
    }
  }
  const fetchAssessments = () => {
    const { pageNo, search } = _filter.current;
    dispatch(eduActions.fetchAssessments({ pageNo, search }))
  }
  const handlePageChange = (pageNo) => {
    _filter.current = { ..._filter.current, pageNo: pageNo }
    fetchAssessments();
  }
  const getColumns = () => {
    let cols = Columns.map((_) => ({ ..._ }));
    cols[1].onClick = handleEvaluationSelect;
    if (!(isAdmin(user) || isArchitect(user) || isOrganizer(user))) {
      cols = cols.filter((_) => (_.dataIndex !== 'learner'))
      cols[1].width = '75%';
    }
    return cols;
  }
  const handleEvaluationSelect = (evaluation) => {
    if (evaluation) {
      dispatch(eduActions.fetchAssessmentById({ id: evaluation.id }))
    }
    setState((_) => ({ ..._, evaluation: evaluation }))
  }
  const handleStartEvaluation = () => {
    setState((_) => ({ ..._, userAnswers: {} }))
  }
  const handleSubmitEvaluation = () => {
    dispatch(eduActions.submitEvaluation({ id: state.evaluation.id }))
    setState((_) => ({ ..._, userAnswers: null, evaluation: null }))
  }
  const handlSaveEvaluationRespond = (evaluationId, userAnswer) => {
    if (state.userAnswers) {
      const userAnswers = { ...state.userAnswers }
      userAnswers[evaluationId] = userAnswer;
      setState((_) => ({ ..._, userAnswers: { ...userAnswers } }))
      dispatch(eduActions.updateEvaluationResponse({ id: evaluationId, answer: userAnswer }))
    }
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search };
    fetchAssessments();
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <DocumentTable
        noSelection
        emptyMessage="There are no evaluations right now!"
        Columns={getColumns()}
        rows={evaluations}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onSearch={handleSearch}
      />
      {
        Boolean(state.evaluation) &&
        <EvaluationAssementor
          allowEdit={false}
          userAnswers={state.userAnswers}
          evaluation={{ ...state.evaluation, ...(evaluation || {}) }}
          onStart={handleStartEvaluation}
          onSubmit={handleSubmitEvaluation}
          onUserChoose={handlSaveEvaluationRespond}
          readOnly={(
            ((state.evaluation.ent_usr_id !== user.id) && (state.evaluation.ent_usr_id !== user.guid)) ||
            (state.evaluation.status === 'passed')
          )}
          onClose={() => handleEvaluationSelect(null)} />
      }
    </div>
  )
}
