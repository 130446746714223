import React, { useEffect, useState, useRef } from 'react';
import { DocumentTable, LearningDocViwer } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { eduActions, getLearnings, getCurrentEduContent, isAdmin, isArchitect, isOrganizer, getCurrentUser, getTotalCount } from '../../store';

const Columns = [
  { title: 'ID', dataIndex: 'learn_id', key: 'learn_id', width: '115px', className: 'exo2 f9 text-center' },
  { title: 'Title', dataIndex: 'name', key: 'name', width: '46%', search: true, render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9', supportServerSearch: true },
  { title: 'Learner', dataIndex: 'learner', key: 'learner', width: '35%', search: true, className: 'exo2 f9' },
  { title: 'Created', dataIndex: 'created_at', key: 'create_at', width: '15%', className: 'exo2 f9 text-center' }
]

export const EduLearningsScreen = (props) => {
  const dispatch = useDispatch();
  const learnings = useSelector(getLearnings);
  const learning = useSelector(getCurrentEduContent);
  const _filter = useRef({ pageNo: 1, search: {} })
  const totalCount = useSelector(getTotalCount.bind(null, 'learnings'))
  const user = useSelector(getCurrentUser);
  const [state, setState] = useState({ learning: null })
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    fetchLearnings()
    handleOpen();
  }, [])
  const handleOpen = () => {
    let open = searchParams.get('open');
    if (open) {
      handleLearningSelect({id: open});
      searchParams.delete('open');
      setSearchParams(searchParams)
    }
  }
  const fetchLearnings = () => {
    const { pageNo, search } = _filter.current;
    dispatch(eduActions.fetchLearnings({ pageNo, search }))
  }
  const handlePageChange = (pageNo) => {
    _filter.current = { ..._filter.current, pageNo: pageNo }
    fetchLearnings();
  }
  const handleLearningSelect = (learning) => {
    if (learning) {
      dispatch(eduActions.fetchLearningById({ id: learning.id }))
    }
    setState((_) => ({ ..._, learning: learning }));
    if (!learning) {
      dispatch(eduActions.setCurrentEduContent(null));
    }
  }
  const getColumns = () => {
    let cols = Columns.map((_) => ({ ..._ }));
    cols[1].onClick = handleLearningSelect;
    if (!(isAdmin(user) || isArchitect(user) || isOrganizer(user))) {
      cols = cols.filter((_) => (_.dataIndex !== 'learner'))
      cols[1].width = '80%';
    }
    return cols;
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search };
    fetchLearnings();
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <DocumentTable
        noSelection
        emptyMessage="There are no learnings right now!"
        Columns={getColumns()}
        rows={learnings}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onSearch={handleSearch}
      />
      {
        Boolean(state.learning) &&
        <LearningDocViwer
          onClose={() => handleLearningSelect(null)}
          learning={{ ...state.learning, ...(learning || {}) }} />
      }
    </div>
  )
}
