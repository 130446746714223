import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { HomeScreen, LoginScreen, EductorHomeScreen, PageNotFoundScreen } from "../screens";

const EducatorRouter = () => {
  return (
    <Routes>
      <Route path="/" index element={<EductorHomeScreen />} />
    </Routes>
  )
}
const MainRouter = () => {
  return (
    <HomeScreen>
      <Routes>
        <Route path="/E/*" index element={<EducatorRouter />} />
        <Route path="/" element={<Navigate replace to="/E" />} />
      </Routes>
    </HomeScreen>
  )
}


function AppRouter() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/login" element={<LoginScreen />} exact />
        <Route path="/page-not-found" element={<PageNotFoundScreen />} exact/>
        <Route path="/*" element={<MainRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
