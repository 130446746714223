import "./saga";
export * from "./slice";

const getEduState = (state) => state.edu;
export const getCirculars = (state) => {
  const _state = getEduState(state);
  return _state.circulars;
}
export const getLearnings = (state) => {
  const _state = getEduState(state);
  return _state.learnings;
}
export const getEvaluations = (state) => {
  const _state = getEduState(state);
  return _state.evaluations;
}
export const getCurrentEduContent = (state) => {
  const _state = getEduState(state);
  return _state.currentContent;
}
export const getOverallStats = (state) => {
  const _state = getEduState(state);
  return _state.overallStats;
}
export const getDepartmentsStats = (state) => {
  const _state = getEduState(state);
  return _state.deptStats;
}
export const getTotalCount = (key, state) => {
  const _state = getEduState(state);
  return _state.totalCounts[key];
}