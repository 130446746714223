import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import { useSelector } from "react-redux";
import { EduDashboardScreen } from "./Dashboard";
import { EduCircularsScreen } from "./Circulars";
import { EduEvaluationsScreen } from "./Evaluations";
import { EduLearningsScreen } from "./Learnings";
import { getCurrentUser, isAdmin, isArchitect, isOrganizer, isAccessor } from "../../store";
import "./style.scss"

const TabItems = [
  { label: 'Stats', key: 'S' },//renamed Dashboard to Stats
  { label: 'Learning', key: 'L' },
  { label: 'Circular', key: 'C' },
  { label: 'Assessment', key: 'A' },
]

const getTab = (isSuperUser) => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  let defaultTab = isSuperUser ? 'S' : 'L'
  return currentUrlParams.get('tab') || defaultTab
}

export const EductorHomeScreen = (props) => {
  const navigate = useNavigate();
  const user = useSelector(getCurrentUser)
  const isSuperUser = isAdmin(user) || isArchitect(user) || isOrganizer(user) || isAccessor(user);
  const [state, setState] = useState({ tab: getTab(isSuperUser), selected: null })
  useEffect(() => {
    if (state.tab) {
      let index = TabItems.findIndex((_) => (_.key === state.tab))
      if (index === -1 || (index === 0 && !isSuperUser)) {
        navigate('/page-not-found')
      }
    }
  }, [state])
  return (
    <div className='row v-start w-100 h-100 o-hide screen-pad bgWhite'>
      <div className='col f-rest h-100'>
        <h4 className='exo2 f4 med'>Education</h4>
        <Tabs
          activeKey={state.tab}
          onChange={(key) => {
            setState((_) => ({ ..._, tab: key, selected: null }))
            navigate(`${window.location.pathname}?tab=${key}`)
          }}
          items={
            TabItems.filter(({ key }) => {
              return key === 'S' ? isSuperUser : true
            })
          } />
        <div className='col f-rest  o-hide'>
          {
            (state.tab === TabItems[0].key && isSuperUser) &&
            <EduDashboardScreen />
          }
          {
            state.tab === TabItems[1].key &&
            <EduLearningsScreen />
          }
          {
            state.tab === TabItems[2].key &&
            <EduCircularsScreen />
          }
          {
            state.tab === TabItems[3].key &&
            <EduEvaluationsScreen />
          }
        </div>
      </div>
    </div>
  )
}
