import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, setAlertAndLoading, axios, requestHandler, getDateInFormat, dateSorter } from "../common";
import { eduActions } from "./slice";
import { saveAs } from 'file-saver';
import moment from "moment";

const fetchS3ObjectAsBlob = (url, type) => {
  return fetch(url, { method: "GET", headers: axios.getAuthTokenHeader() }).then(_ => _.blob())
}

function* fetchCirculars({ payload }) {
  yield requestHandler(function* () {
    const pageNo = payload && payload.pageNo ? payload.pageNo : 1;
    const pageSize = payload && payload.pageSize ? payload.pageSize : 15;
    const search = payload && payload.search ? payload.search : {};
    const body = { circular: search }
    const res = yield axios.post(`/educators/user_circular_search?page=${pageNo}&per_page=${pageSize}`, body);
    let circulars = res.circular_list ? res.circular_list : res;
    circulars = Array.isArray(circulars) ? circulars : [];
    circulars.sort(dateSorter.bind(null, 'created_at'));
    circulars = circulars.map((_) => {
      let cir = { ...(_.circular || {}), id: _.id, cir_id: _.cir_id };
      cir.created_at = getDateInFormat(_.created_at)
      if (_.user) {
        cir.learner = _.user.email;
      }
      return cir;
    })
    const totalCount = res.total_count || circulars.length;
    yield put(eduActions.setTotalCount({ key: 'circulars', count: totalCount }))
    yield put(eduActions.setCirculars(circulars))
  }, "Failed to load Circulars");
}
function* fetchAssessments({ payload }) {
  yield requestHandler(function* () {
    const pageNo = payload && payload.pageNo ? payload.pageNo : 1;
    const pageSize = payload && payload.pageSize ? payload.pageSize : 15;
    const search = payload && payload.search ? payload.search : {};
    const body = { evaluation: search }
    const res = yield axios.post(`/educators/user_evaluation_search?page=${pageNo}&per_page=${pageSize}`, body);
    let evaluations = res.evaluation_list ? res.evaluation_list : res;
    evaluations = Array.isArray(evaluations) ? evaluations : [];
    evaluations.sort(dateSorter.bind(null, 'created_at'));
    evaluations = evaluations.map((_) => {
      let ev = { ..._.evaluation, ..._, eval_id: _.eval_id };
      ev.created_at = moment(_.created_at).add(30, "days");
      ev.created_at = getDateInFormat(ev.created_at);
      if (ev.status === 'completed' || ev.status === 'Completed') {
        ev.status = ev.pass_fail;
      }
      ev.status = ev.status === 'in_progress' ? 'started' : (ev.status || 'pending');
      if (ev.user) {
        ev.learner = ev.user.email;
      }
      return ev
    });
    const totalCount = res.total_count || evaluations.length;
    yield put(eduActions.setTotalCount({ key: 'assessments', count: totalCount }))
    yield put(eduActions.setEvaluations(evaluations))
  }, "Failed to load evaluations");
}
function* fetchLearnings({ payload }) {
  yield requestHandler(function* () {
    const pageNo = payload && payload.pageNo ? payload.pageNo : 1;
    const pageSize = payload && payload.pageSize ? payload.pageSize : 15;
    const search = payload && payload.search ? payload.search : {};
    const body = { learning: search }
    const res = yield axios.post(`/educators/user_learning_search?page=${pageNo}&per_page=${pageSize}`, body);
    let learnings = res.learning_list ? res.learning_list : res;
    learnings = Array.isArray(learnings) ? learnings : [];
    learnings.sort(dateSorter.bind(null, 'created_at'));
    learnings = learnings.map((_, i) => {
      let ev = { ..._.learning, id: _.id, learn_id: _.learn_id };
      ev.title = _.name || `Learning 0${i + 1}`
      ev.created_at = getDateInFormat(_.created_at)
      if (_.user) {
        ev.learner = _.user.email;
      }
      return ev
    });
    const totalCount = res.total_count || learnings.length;
    yield put(eduActions.setTotalCount({ key: 'learnings', count: totalCount }))
    yield put(eduActions.setLearnings(learnings))
  }, "Failed to load learnings");
}
function* fetchOverallStats({ payload }) {
  yield requestHandler(function* () {
    const { pageNo } = payload
    const res = yield axios.get(`/educators/overall_coverage_dashboard?page=${pageNo}&per_page=10`);
    let overall = [];
    if (res.overall_coverage) {
      for (const key in res.overall_coverage) {
        if (Object.hasOwnProperty.call(res.overall_coverage, key)) {
          const _ = res.overall_coverage[key];
          const entry = { ...(_[1] || {}) }
          entry.title = _[0];
          overall = overall.concat(entry)
        }
      }
    }
    yield put(eduActions.setOverallStats({ items: overall, totalCount: res.total_count }))
  }, "Failed to load Overall stats");
}
function* fetchDeptStats({ payload }) {
  yield requestHandler(function* () {
    const { pageNo } = payload
    const res = yield axios.get(`/educators/department_converage_dashboard?page=${pageNo}&per_page=10`);
    let depts = [];
    if (res.department_converage) {
      for (const key in res.department_converage) {
        if (Object.hasOwnProperty.call(res.department_converage, key)) {
          const deptCov = res.department_converage[key];
          depts = depts.concat({ title: deptCov[0], department_name: deptCov[1], passed: deptCov[2], coverage: deptCov[3] })
        }
      }
    }
    yield put(eduActions.setDepartmentsStats({ items: depts, totalCount: res.total_count }))
  }, "Failed to load Department stats");
}
function* startEvaluation({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload;
    yield axios.post('/educators/start_evaluation', { evaluation_id: id });
    yield setAlertAndLoading(null, { message: 'Assessment Started Successfully.' })
  }, "Failed to load start evaluation");
}
function* updateEvaluationResponse({ payload }) {
  yield requestHandler(function* () {
    const { id, answer } = payload;
    yield axios.post('/educators/update_question', { user_question_id: id, answer });
  }, "Failed to update evalaution response");
}
function* submitEvaluation({ payload }) {
  yield requestHandler(function* () {
    const { id } = payload;
    const res = yield axios.post('/educators/submit_evaluation', { user_evaluation_id: id });
    if (res && !res.passed) {
      yield setAlertAndLoading(null, { message: 'Compliance NOT Achieved', description: 'Retake Assessment', severity: 'warning', type: 'modal' })
      return;
    }
    yield put(eduActions.fetchAssessments())
    yield setAlertAndLoading(null, { message: 'Assessment Submitted Successfully.' })
  }, "Failed to update evalaution response");
}
function* fetchAssessmentById({ payload }) {
  yield requestHandler(function* () {
    yield put(eduActions.setCurrentEduContent(null))
    let res = yield axios.post('/educators/start_evaluation', { user_evaluation_id: payload.id });
    res.questions = res.user_questions;
    yield put(eduActions.setCurrentEduContent(res))
  }, "Failed to fetch evalaution");
}
function* fetchCircularById({ payload }) {
  yield requestHandler(function* () {
    yield put(eduActions.setCurrentEduContent(null))
    const res = yield axios.post('/educators/show_user_circular', { user_circular_id: payload.id });
    if (res.user_circular) {
      res.user_circular.scheduledAt = getDateInFormat(res.user_circular.created_at, 'YY-MM-DD')
    }
    yield put(eduActions.setCurrentEduContent(res))
  }, "Failed to fetch evalaution");
}
function* fetchLearningById({ payload }) {
  yield requestHandler(function* () {
    yield put(eduActions.setCurrentEduContent(null))
    const res = yield axios.post('/educators/show_user_learning', { user_learning_id: payload.id });
    if (res.learn_type === 'presentation' && Array.isArray(res.attachments) && res.attachments.length > 0) {
      let url = res.attachments[0].evidence_url
      res.attachments[0].evidence_url_full = axios.getFullPath(`/educators/s3_download_file?url=${url}`);
      res.attachments[0].blob = yield fetchS3ObjectAsBlob(res.attachments[0].evidence_url_full);
    }
    yield put(eduActions.setCurrentEduContent(res))
  }, "Failed to fetch evalaution");
}

function* exportOverallStats({ payload }) {
  yield requestHandler(function* () {
    const { type, pageNo, pageSize } = payload;
    let res;
    if (type === 'csv') {
      res = yield axios.get('/educators/export_overall_coverage');
      var blob = new Blob([res], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "Educator Overall Statistics.csv");
    } else if (type === 'png') {
      const res = yield fetch(axios.getFullPath(`/educators/export_overall_coverage_to_png?page=${pageNo}&per_page=${pageSize || 10}`), { method: "GET", headers: axios.getAuthTokenHeader() })
        .then(response => {
          if (response.status === 200) {
            return response.blob();
          } else {
            return { message: response.statusText, error: true };
          }
        });
      if (res && res.error) {
        yield setAlertAndLoading(null, { message: 'Failed to export : ' + (res.message || ''), success: false })
      } else {
        saveAs(res, "Educator Overall Statistics.png");
      }
    }
  }, "Failed to export overall statistics");
}
function* exportDeptStats({ payload }) {
  yield requestHandler(function* () {
    const { type, pageNo, pageSize } = payload;
    let res;
    if (type === 'csv') {
      res = yield axios.get('/educators/export_department_converage');
      var blob = new Blob([res], { type: "text/csv;charset=utf-8" });
      saveAs(blob, "Educator Department Statistics.csv");
    } else if (type === 'png') {
      const res = yield fetch(axios.getFullPath(`/educators/export_department_converage_to_png?page=${pageNo}&per_page=${pageSize || 10}`), { method: "GET", headers: axios.getAuthTokenHeader() })
        .then(response => {
          if (response.status === 200) {
            return response.blob();
          } else {
            return { message: response.statusText, error: true };
          }
        });
      if (res && res.error) {
        yield setAlertAndLoading(null, { message: 'Failed to export : ' + (res.message || ''), success: false })
      } else {
        saveAs(res, "Educator Department Statistics.png");
      }

    }
  }, "Failed to export department wise statistics");
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("edu/fetchCirculars", fetchCirculars);
  yield takeEvery("edu/fetchAssessments", fetchAssessments);
  yield takeEvery("edu/fetchLearnings", fetchLearnings);
  yield takeEvery("edu/fetchDeptStats", fetchDeptStats);
  yield takeEvery("edu/fetchOverallStats", fetchOverallStats);
  yield takeEvery("edu/fetchAssessmentById", fetchAssessmentById);
  yield takeEvery("edu/fetchCircularById", fetchCircularById);
  yield takeEvery("edu/fetchLearningById", fetchLearningById);
  yield takeEvery("edu/startEvaluation", startEvaluation);
  yield takeEvery("edu/submitEvaluation", submitEvaluation);
  yield takeEvery("edu/exportOverallStats", exportOverallStats);
  yield takeEvery("edu/exportDeptStats", exportDeptStats);
  yield takeEvery("edu/updateEvaluationResponse", updateEvaluationResponse);
})