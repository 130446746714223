import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../common";

const initialState = {
  circulars: null,
  learnings: null,
  evaluations: null,
  currentContent: null,
  overallStats: null,
  deptStats: null,
  totalCounts: {
    learnings: 0,
    circulars: 0,
    assessments: 0
  }
}


const eduSlice = createSlice({
  name: "edu",
  initialState: initialState,
  reducers: {
    fetchCirculars: () => { },
    fetchLearnings: () => { },
    fetchAssessments: () => { },
    fetchCircularById: () => { },
    fetchLearningById: () => { },
    fetchAssessmentById: () => { },
    fetchDeptStats: () => { },
    fetchOverallStats: () => { },
    submitEvaluation: () => { },
    startEvaluation: () => { },
    updateEvaluationResponse: () => { },
    exportOverallStats: () => { },
    exportDeptStats: () => { },
    setCirculars: (state, action) => {
      state.circulars = action.payload
    },
    setLearnings: (state, action) => {
      state.learnings = action.payload
    },
    setEvaluations: (state, action) => {
      state.evaluations = action.payload
    },
    setCurrentEduContent: (state, action) => {
      state.currentContent = action.payload
    },
    setOverallStats: (state, action) => {
      state.overallStats = action.payload
    },
    setDepartmentsStats: (state, action) => {
      state.deptStats = action.payload
    },
    setTotalCount: (state, action) => {
      const { key, count } = action.payload
      state.totalCounts[key] = count;
    }
  }
})
export const eduActions = eduSlice.actions;

ReducerRegistry.register("edu", eduSlice.reducer)