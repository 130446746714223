import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DocumentTable, Button, Menu } from "../../components";
import { eduActions, getDepartmentsStats, getOverallStats } from '../../store';

const ExportMenu = [
  { id: 'csv', label: "csv", selection: true },
  { id: 'png', label: "png", selection: true },
]

const OverallTabCols = [
  { title: 'Assessment', dataIndex: 'title', key: 'title', width: '60%', className: 'exo2 f9 ', search: true },
  { title: 'Passed', dataIndex: 'passed', key: 'pass_percentage', width: '20%', className: 'exo2 f9 ', },
  { title: 'Coverage', dataIndex: 'coverage', key: 'coverage', width: '20%', sort: true, className: 'exo2 f9 ', },
];

const DeptTabCols = [
  { title: 'Assessment', dataIndex: 'title', key: 'title', width: '40%', className: 'exo2 f9 ', search: true },
  { title: 'Department', dataIndex: 'department_name', key: 'department', width: '20%', className: 'exo2 f9 ', search: true },
  { title: 'Passed', dataIndex: 'passed', key: 'pass_percentage', width: '20%', className: 'exo2 f9 ', },
  { title: 'Coverage', dataIndex: 'coverage', key: 'coverage', width: '20%', sort: true, className: 'exo2 f9 ', },
]

const IndividualTabCols = [
  { title: 'Assessment', dataIndex: 'title', key: 'title', width: '40%', className: 'exo2 f9 ', search: true },
  { title: 'Department', dataIndex: 'dept_name', key: 'dept_name', width: '20%', className: 'exo2 f9 ', search: true },
  { title: 'Name', dataIndex: 'name', key: 'name', width: '20%', search: true, className: 'exo2 f9 ', },
  { title: 'Status', dataIndex: 'status', key: 'status', render: 'color', width: '20%', sort: true, className: 'exo2 f9 ', },
]

const DashboardTable = (props) => {
  const [state, setState] = useState({ showMenu: null, pageNo: 1 })
  const { cols, rows, title, className, totalCount } = props;
  const handleExport = (e) => {
    setState((_) => ({ ..._, showMenu: e.target }))
  }
  const handleMenuClick = (e, menu) => {
    handleExport({ target: null })
    props.onExport && props.onExport(menu.id, state.pageNo)
  }
  const handlePageChange = (pageNo) => {
    setState((_) => ({ ..._, pageNo }))
    props.onPageChange && props.onPageChange(pageNo)
  }
  return (
    <div className={`col dash-table o-hide ${className || ''}`}>
      <div className='col w-100 h-100 f-rest o-hide bgWhite'>
        <div className='row title-row w-100 h-btn v-ctr'>
          <h6 className='reg exo2 f5 title'>{title}</h6>
          <Button
            label='Export' className='row row-reverse btn-export' icon='icon-export' variant='lite'
            font='f10 med' iconColor='c0133CC' color='#0133CC'
            onClick={handleExport}
          />
        </div>
        <div className='col f-rest o-hide'>
          <DocumentTable
            noSelection Columns={cols}
            rows={rows}
            totalCount={totalCount}
            onPageChange={handlePageChange} />
        </div>
      </div>
      <Menu
        menuItems={ExportMenu}
        anchorEl={state.showMenu}
        onMenuClick={handleMenuClick}
        onClose={() => handleExport({ target: null })} />
    </div>
  )
}
export const EduDashboardScreen = (props) => {
  const dispatch = useDispatch()
  const overall = useSelector(getOverallStats)
  const depts = useSelector(getDepartmentsStats)
  useEffect(() => {
    fetchOverallStats(1)
    fetchDeptStats(1)
  }, [])
  const fetchOverallStats = (pageNo) => {
    dispatch(eduActions.fetchOverallStats({ pageNo: pageNo }))
  }
  const fetchDeptStats = (pageNo) => {
    dispatch(eduActions.fetchDeptStats({ pageNo: pageNo }))
  }
  const handleExportDepartment = (type, pageNo) => {
    dispatch(eduActions.exportDeptStats({ type, pageNo }))
  }
  const handleExportOverall = (type, pageNo) => {
    dispatch(eduActions.exportOverallStats({ type, pageNo }))
  }
  return (
    <div className='row w-100 h-100 oy-auto fwarp h-btn'>
      <DashboardTable
        className=''
        title="Overall Coverage"
        rows={overall ? overall.items : null}
        totalCount={overall ? overall.totalCount : 0}
        onExport={handleExportOverall}
        onPageChange={fetchOverallStats}
        cols={OverallTabCols} />
      <DashboardTable
        className=''
        title="Departmental Coverage"
        cols={DeptTabCols}
        rows={depts ? depts.items : null}
        onPageChange={fetchDeptStats}
        totalCount={depts ? depts.totalCount : 0}
        onExport={handleExportDepartment}
      />
      {/* <DashboardTable className='w-100 h-50' title="Individual Coverage " rows={[]} cols={OverallTabCols} /> */}
    </div>
  )
}
