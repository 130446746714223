import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, setAlertAndLoading, axios, getUserDetails, requestHandler } from "../common";
import { userActions } from "./slice";
const KEY_NM_API = 'NM-API-Key';
const AUDIT_NM_API = 'AUDIT-API-Key';

function* validateTokenAPI(token) {
  const header = { [KEY_NM_API]: token };
  let user;
  try {
    const res = yield axios.post('/tasks/api_user_detail', { task: { api_key: token } }, header);
    user = res.errors ? 'Error' : res
    if (user !== 'Error') {
      localStorage.setItem(KEY_NM_API, token);
      getUserDetails(user)
      const mod = yield axios.get('/consoles/subscribed_grc_modules', header);
      user.modules = mod.modules;
    }
  } catch (e) {
    user = 'Error'
  }
  return user;
}
const getNewNMIKEYObject = (longSessionToken) => {
  let sessionObj = {};
  if (longSessionToken && longSessionToken.trim().length > 0) {
    longSessionToken = longSessionToken.trim();
    sessionObj = window.atob(longSessionToken);
    sessionObj = JSON.parse(sessionObj);
  }
  return sessionObj;
}
function* validateToken({ payload }) {
  try {
    let NMKey, sessionObj, user = 'Error', token = payload && payload.token;
    if (!token) {
      token = localStorage.getItem(AUDIT_NM_API);
    }
    sessionObj = getNewNMIKEYObject(token);
    NMKey = sessionObj[KEY_NM_API];
    if (NMKey) {
      user = yield validateTokenAPI(NMKey)
    }
    if (user !== 'Error' && token) {
      user.session_token = token;
      localStorage.setItem(AUDIT_NM_API, token)
    }
    yield put(userActions.logInSuccess({ current_ent_usr: user, status: user !== 'Error' }));
    yield setAlertAndLoading(false)
  } catch (error) {
    if (error.status === 401) {
      localStorage.removeItem(AUDIT_NM_API);
      localStorage.removeItem(KEY_NM_API);
      yield put(userActions.logInError());
      let message = {
        autoClose: false,
        type: 'modal',
        message: "LogIn is required",
        description: "Your session is successfully logged out."
      }
      yield setAlertAndLoading(false, message);
    } else {
      yield put(userActions.logInError());
      yield setAlertAndLoading(false)
    }
  }
}
function* logout() {
  yield requestHandler(function* () {
    try {
      yield axios.post('/consoles/logout_api_user', {});
    } catch (error) {
      console.error("Logout Failed", error)
    }
    localStorage.removeItem(KEY_NM_API);
    localStorage.removeItem(AUDIT_NM_API);
    yield put(userActions.logoutSuccess());
    yield setAlertAndLoading(false);
  });
}
SagaRegistry.register(function* userSaga() {
  yield takeEvery("user/validateToken", validateToken);
  yield takeEvery("user/logout", logout)
})