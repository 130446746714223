import React, { useEffect, useState, useRef } from 'react';
import { DocumentTable, CircularTemplateDialog } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { eduActions, getCirculars, getCurrentUser, getCurrentEduContent, isAdmin, isArchitect, isOrganizer, getTotalCount } from '../../store';

const Columns = [
  { title: 'ID', dataIndex: 'cir_id', key: 'cir_id', width: '115px', className: 'exo2 f9 text-center' },
  { title: 'Title', dataIndex: 'name', key: 'name', width: '46%', search: true, render: 'btn', variant: 'lite', color: '#0033CC', className: 'exo2 f9', supportServerSearch: true },
  { title: 'Learner', dataIndex: 'learner', key: 'learner', width: '35%', search: true, className: 'exo2 f9' },
  { title: 'Created', dataIndex: 'created_at', key: 'create_at', width: '15%', className: 'exo2 f9 text-center' }
]

export const EduCircularsScreen = (props) => {
  const dispatch = useDispatch();
  const _filter = useRef({ pageNo: 1, search: {} })
  const circulars = useSelector(getCirculars);
  const circular = useSelector(getCurrentEduContent);
  const totalCount = useSelector(getTotalCount.bind(null, 'circulars'))
  const user = useSelector(getCurrentUser)
  const [state, setState] = useState({ circular: null })
  useEffect(() => {
    fetchCirculars()
  }, [])
  const fetchCirculars = () => {
    const { pageNo, search } = _filter.current;
    dispatch(eduActions.fetchCirculars({ pageNo, search }))
  }
  const handlePageChange = (pageNo) => {
    _filter.current = { ..._filter.current, pageNo: pageNo }
    fetchCirculars();
  }
  const handleCircularSelect = (circular) => {
    if (circular) {
      dispatch(eduActions.fetchCircularById({ id: circular.id }))
    }
    setState((_) => ({ ..._, circular: circular }));
    if (!circular) {
      dispatch(eduActions.setCurrentEduContent(null));
    }
  }
  const getColumns = () => {
    let cols = Columns.map((_) => ({ ..._ }));
    cols[1].onClick = handleCircularSelect;
    if (!(isAdmin(user) || isArchitect(user) || isOrganizer(user))) {
      cols = cols.filter((_) => (_.dataIndex !== 'learner'))
      cols[1].width = '80%';
    }
    return cols;
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search };
    fetchCirculars();
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <DocumentTable
        noSelection
        emptyMessage="There are no circulars right now!"
        Columns={getColumns()}
        rows={circulars}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onSearch={handleSearch}
      />
      {
        Boolean(state.circular) &&
        <CircularTemplateDialog
          user={user}
          circular={{ ...state.circular, ...(circular || {}) }}
          onClose={() => handleCircularSelect(null)} />
      }
    </div>
  )
}
